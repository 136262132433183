import React, { useContext, useState } from 'react';
import { styled } from 'linaria/react';
import { useCookies } from 'react-cookie';
import SiteContentContext from '../SiteContent/SiteContentContext';
import Image from '@jetshop/ui/Image';
import { ButtonLink } from '../ui/ButtonUI';
import Popup from '../ui/Popup';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';

const Wrapper = styled('div')`
  position: relative;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const PopupContent = styled.div`
  background: ${theme.topBar.background};
  padding: 2rem 1rem;
  ${theme.above.lg} {
    padding: 3rem 3rem;
  }
  &.overlay {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
  .text-content {
    text-align: center;
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${theme.font.secondary};
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span {
      color: white;
    }
    h1 {
      ${getFontSize(theme.startPage.headers.fontSize[1])}
    }
    h2 {
      ${getFontSize(theme.startPage.headers.fontSize[2])}
    }
    h3 {
      ${getFontSize(theme.startPage.headers.fontSize[3])}
    }
    h4 {
      ${getFontSize(theme.startPage.headers.fontSize[4])}
    }
    h5 {
      ${getFontSize(theme.startPage.headers.fontSize[5])}
    }
  }
  > a {
    width: auto;
    background: ${theme.colors.primary};
    border-radius: 30px;
    margin: 0 auto;
    padding: 0 3rem;
    margin-top: 2rem;
  }
`;

const CampaignPopup = () => {
  const {
    popupId,
    popupContent,
    popupImage,
    popupButtonText,
    popupButtonLink,
  } = useContext(SiteContentContext);
  const [cookies, setCookie] = useCookies([popupId]);
  const cookieConsent = cookies[popupId];

  const [visible, setVisible] = useState(cookieConsent !== 'viewed');

  function agree() {
    setCookie(popupId, `viewed`, { maxAge: 3600 * 24 * 30 });
    setVisible(false);
  }

  return popupId && visible ? (
    <Popup
      isOpen={true}
      closePopup={agree}
      className={`${popupImage ? 'no-padding' : ''} campaign-popup`}
    >
      <Wrapper>
        {popupImage && (
          <ImageWrapper>
            <Image
              aspect={'1:1'}
              sizes={[1, 1, 1, 800]}
              src={popupImage}
              alt={popupImage}
              crop={true}
              cover={true}
            />
          </ImageWrapper>
        )}
        <PopupContent className={popupImage ? 'has-image' : ''}>
          <div className="text-content">{popupContent}</div>
          <ButtonLink onClick={agree} to={popupButtonLink}>
            {popupButtonText}
          </ButtonLink>
        </PopupContent>
      </Wrapper>
    </Popup>
  ) : null;
};

export default CampaignPopup;
