import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { ProductInfoMarginBottom } from '../StyledComponents';

const StyledButton = styled('button')`
  background: transparent;
  border: 2px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  //width: 31.333333%;
  padding: 0.3rem 0.7rem;
  margin: 0 1% 2%;
  font-size: 0.9rem;
  ${theme.above.md} {
    //width: 23%;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    margin: 0 1% 2%;
  }
  &[data-selected='true'] {
    border-color: ${theme.colors.font};
  }
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
  &.not-buyable,
  &[data-disabled='true'] {
    border-color: #eee;
    background: ${theme.colors.greys[0]};
    color: #aaa;
    text-decoration: line-through;
    &[data-selected='true'] {
      border-color: #777;
    }
  }
  &.color {
    padding: 0;
    .color-circle {
      > div {
        width: 36px;
        height: 36px;
        border-radius: 0;
        border: none;
      }
    }
  }
`;
const VariantButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -1% 0;
  ${ProductInfoMarginBottom}
  &.invalid button {
    box-shadow: 0 0 10px rgba(195, 45, 45, 0.38);
  }
`;

export const VariantHeading = styled('h4')`
  margin-bottom: 0.3rem;
`;

const VariantWrapper = styled('div')`
  width: 100%;
`;

const VariantButton = ({ option, variantHandler, showValidationMessage }) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection,
  } = variantHandler;
  const selectedValue = getSelectedValue(option);

  return (
    <VariantWrapper>
      <VariantHeading>{option.name}</VariantHeading>
      <VariantButtonWrapper className={showValidationMessage && 'invalid'}>
        {option.values.map((value) => {
          const validation = validateSelection(value, option);
          const item = getVariantForSelection(value, option);
          return (
            <StyledButton
              key={option.name + value}
              onClick={() => selectValue(value, option)}
              data-disabled={validation === 'invalid'}
              data-secondary={value !== selectedValue}
              data-selected={value === selectedValue}
              className={`${
                item && item.stockStatus && !item.stockStatus.buyable
                  ? 'not-buyable '
                  : ''
              }`}
            >
              <span>{value}</span>
            </StyledButton>
          );
        })}
      </VariantButtonWrapper>
    </VariantWrapper>
  );
};

export default VariantButton;
