import React from 'react';
import { styled } from 'linaria/react';
import ReactModal from 'react-modal';
import CloseButton from './CloseButton';
import { theme } from '../Theming/Theming';

const Modal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 1200px;
  min-width: 320px;
  max-height: 90vh;
  transform: translateX(-50%) translateY(-50%);
  outline: none;
  &.image {
    width: 100%;
  }
  ${theme.below.lg} {
    max-height: 95%;
    width: 95%;
  }
  &.no-padding {
    .popup-inner {
      padding: 0;
    }
  }
  &.campaign-popup {
    ${theme.above.lg} {
      width: 600px;
    }
  }
`;

const standardStyles = {
  overlay: {
    background: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

const ModalInner = styled('div')`
  width: 100%;
  background: white;
  padding: 1rem;
`;

const ModalContent = styled('div')``;

const Close = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
  z-index: 2;
  button {
    margin: 0;
    padding: 0;
  }
  ${theme.below.sm} {
    padding: 0.5rem;
  }
`;

const Popup = ({
  children,
  isOpen,
  closePopup,
  modalStyles = { ...standardStyles },
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closePopup}
      style={modalStyles}
      className={className}
      ariaHideApp={false}
    >
      <ModalInner className="popup-inner">
        <Close>
          <CloseButton onClick={closePopup} />
        </Close>
        <ModalContent className="popup-content">{children}</ModalContent>
      </ModalInner>
    </Modal>
  );
};

export default Popup;
