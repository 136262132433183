import React from 'react';
import Image from '@jetshop/ui/Image';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Logo = (props) => {
  let src = '/pub_images/original/betteryou-logo.png';
  return (
    <ChannelContext.Consumer>
      {({ selectedChannel }) => {
        if (selectedChannel && selectedChannel.id === 2)
          src = '/pub_images/original/betteryou-logo.png';
        return <Image src={src} sizes={[400]} aspect={'16:7'} />;
      }}
    </ChannelContext.Consumer>
  );
};

export default Logo;
