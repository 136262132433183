import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Above } from '@jetshop/ui/Breakpoints';

const ReviewsWrapper = styled('div')``;

const TrustpilotWidget = ({ type, product, id }) => {
  const { selectedChannel } = useContext(ChannelContext);

  let articleNumber = product && product.articleNumber;

  const common = {
    id: id,
    className: 'trustpilot-widget',
    dataLocale: selectedChannel.language
      ? selectedChannel.language.culture
      : 'sv_SE',
    dataBusinessunitId: '5d148cae588afe000124128c',
    dataTheme: 'light',
  };

  if (
    product &&
    product.variants &&
    product.variants.values &&
    product.variants.values.length
  ) {
    articleNumber = product.variants.values.map((variant) => {
      return variant.articleNumber;
    });
  }
  switch (type) {
    case 'start-page':
      return (
        <Above breakpoint="lg">
          {(matches) => (
            <div
              id={common.id}
              className={common.className}
              data-locale={common.dataLocale}
              data-businessunit-id={common.dataBusinessunitId}
              data-style-height={matches ? '140px' : '300px'}
              data-style-width="100%"
              data-template-id="53aa8912dec7e10d38f59f36"
              data-stars="1,2,3,4,5"
              data-theme="light"
              data-tags="SelectedReview"
            />
          )}
        </Above>
      );
    case 'micro-stars':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="22px"
          data-style-width="300px"
          data-template-id="5419b732fbfb950b10de65e5"
          data-stars="1,2,3,4,5"
          data-theme="dark"
          data-tags="SelectedReview"
          data-review-languages={common.dataLocale}
        />
      );
    case 'mini-stars':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="150px"
          data-style-width="100%"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-theme={'light'}
          data-tags="SelectedReview"
        />
      );
    case 'product-page-mini':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-template-id="577258fb31f02306e4e3aaf9"
          data-style-height="24px"
          data-style-width="170px"
          data-theme={'light'}
          data-stars="1,2,3,4,5"
          data-star-color="#3c3d3c"
          data-sku={articleNumber}
          data-name={product && product.name}
          data-no-reviews="hide"
          data-scroll-to-list="true"
          data-allow-robots="true"
        />
      );
    case 'product-page-full':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-template-id="5763bccae0a06d08e809ecbb"
          data-style-height="700px"
          data-style-width="100%"
          data-theme={'light'}
          data-stars="1,2,3,4,5"
          data-star-color="#3c3d3c"
          data-sku={articleNumber}
          data-name={product && product.name}
          data-review-languages="sv"
          data-no-reviews="hide"
          data-scroll-to-list="true"
          data-allow-robots="true"
        />
      );
    case 'category-product-mini':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-template-id="577258fb31f02306e4e3aaf9"
          data-style-height="18px"
          data-style-width="170px"
          data-theme={'light'}
          data-stars="1,2,3,4,5"
          data-star-color="#3c3d3c"
          data-sku={articleNumber}
          data-name={product && product.name}
          data-review-languages="sv"
          data-no-reviews="hide"
          data-scroll-to-list="false"
          data-allow-robots="true"
        />
      );
    default:
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="1000px"
          data-style-width="100%"
          data-template-id="539adbd6dec7e10e686debee"
          data-stars="1,2,3,4,5"
          data-theme={common.dataTheme}
        />
      );
  }
};

export const TrustpilotScript = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  });
  return null;
};

const TrustPilot = ({ type, product = null }) => {
  const id = 'trustpilot-' + type + '-' + product?.id;

  React.useEffect(() => {
    var checkTrustVar = setInterval(checkTrustWidget, 100);
    function checkTrustWidget() {
      const trustWidget = document.getElementById(id);
      if (trustWidget && window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustWidget);
        clearInterval(checkTrustVar);
      }
    }
  }, [id]);

  return (
    <ReviewsWrapper className="trustpilot-wrapper">
      <TrustpilotWidget type={type} product={product} id={id} />
    </ReviewsWrapper>
  );
};

export default TrustPilot;
