import { createBreakPoints } from './helpers';

const labels = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
const breakpoints = ['0px', '20rem', '40rem', '64rem', '80rem', '100rem'];

export const mediaQueries = createBreakPoints(labels, breakpoints);
const cssMediaQueries = createBreakPoints(labels, breakpoints, true);

//USING THIS TO HAVE REM LIKE MANAGMENT OF HEADER-SIZES WHICH OFTEN HAVE A DIFFERENT FONT
const remCalc = function (size) {
  if (isNaN(size)) {
    size = parseInt(size);
  }
  return size / typography.size.replace('px', '') + 'rem';
};

//COLORS VARIABLES
const colors = {
  white: '#FFF',
  black: '#111',
  red: '#E82A2A',
  green: '#69a24c',
  yellow: '#FFCB37',
  font: '#222',
  primary: '#97c049',
  primaryLight: '#BEDA92',
  primaryDark: '#1F3E1E',
  secondary: '#555',
  secondaryLight: '#777',
  secondaryDark: '#333',
  discount: '#b44e3c',
  link: '#ce3c8a',
  border: '#DDD',
  borderLight: '#F1F1F1',
  borderDark: '#333',
  borderInput: '#AAA',
  lightgrey: '#f6f6f6',
  vanillaWhite: '#fbf8f4',
  grey: '#565558',
  error: '#ff3131',
  success: '#69a24c',
  greys: [
    '#F9F9F9',
    '#F1F1F1',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#3B3B3B',
  ],
};
colors.buy = colors.primary;
colors.backgroundLight = '#F5F5F5';
colors.backgroundDark = colors.primary;
colors.inStock = '#339600';
colors.outOfStock = colors.red;

//FONTS
const font = {
  primary: 'fieldwork',
  secondary: 'korolev-condensed',
};

const weights = {
  ultra: 100,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

//TYPOGRAPHY
let typography = {
  fontFamily: font.primary,
  fontWeight: weights.light,
  size: '14px',
  letterSpacing: '0',
  paragraph: {
    fontSize: '1rem',
    lineHeight: '1.5em',
    marginBottom: '1rem',
  },
  header: {
    fontFamily: font.primary,
    textTransform: 'uppercase',
    fontWeight: weights.normal,
    letterSpacing: '0',
    secondColor: {
      color: colors.primary,
    },
  },
};

//HEADER ALT FONT
typography.header.secondFont = {
  fontFamily: font.primary,
  fontWeight: weights.normal,
  textTransform: 'uppercase',
  letterSpacing: typography.letterSpacing,
};
//HEADER FONTSIZES h1-h5 (sm & md & lg & xl)
typography.header.fontSize = [
  '__',
  [remCalc(26), remCalc(28), remCalc(31), remCalc(40)],
  [remCalc(20), remCalc(22), remCalc(24), remCalc(28)],
  [remCalc(16), remCalc(16), remCalc(18), remCalc(18)],
  [remCalc(14), remCalc(14), remCalc(14), remCalc(14)],
  [remCalc(12), remCalc(12), remCalc(12), remCalc(12)],
];

const general = {
  maxWidth: '1600px',
  maxWidthText: '1000px',
  pagePadding: {
    lg: '1rem',
    md: '1rem',
    sm: '1rem',
  },
  hallmarkBorder: '2px solid ' + colors.borderDark,
  radius: '3px',
  boxShadow: '0px 1px 2px #0000000d',
};

//BUTTON
const button = {
  background: colors.primary,
  backgroundSecondary: '#333',
  color: colors.white,
  colorSecondary: colors.white,
  fontFamily: font.primary,
  fontWeight: weights.light,
  fontSize: typography.header.fontSize[4],
  ListFontSize: typography.header.fontSize[5],
  textTransform: 'uppercase',
  letterSpacing: '1.5px',
};

//AREAS

//TOPBAR
const topBar = {
  height: {
    lg: 36,
    md: 36,
    sm: 36,
    scroll: 0,
  },
  color: colors.white,
  background: colors.primaryDark,
  fontSize: '0.9rem',
  fontWeight: weights.light,
  usp: {
    fontSize: '0.9rem',
    fontWeight: weights.light,
  },
};

//DESKTOP HEADER
const desktopHeader = {
  height: {
    lg: 70,
    md: 70,
    scroll: 70,
  },
  search: {
    height: {
      lg: 30,
      md: 30,
    },
  },
  logo: {
    height: {
      lg: 50,
      md: 50,
      scroll: 50,
    },
  },
  background: colors.white,
  color: colors.font,
  nav: {
    padding: ['0.6rem', '1rem'],
    fontSize: [remCalc(14), remCalc(14)],
    nameTag: 'h3',
    fontWeight: typography.header.fontWeight,
    barHoverColor: colors.buy,
    subMenu: {
      lv2: {
        fontSize: '1.1rem',
      },
      lv3: {
        fontSize: '0.9rem',
      },
    },
    height: {
      lg: 70,
      md: 70,
      scroll: 70,
    },
    useSubMenuImage: true,
  },
};

//MOBILE HEADER
const mobileHeader = {
  height: {
    xs: 60,
    sm: 60,
    scroll: 60,
  },
  search: {
    height: {
      xs: 60,
      sm: 60,
    },
  },
  logo: {
    height: 36,
  },
  background: colors.white,
  color: colors.font,
  nav: {
    padding: '3rem',
    lv1: {
      fontSize: remCalc(19),
      fontWeight: typography.header.fontWeight,
      fontFamily: typography.header.fontFamily,
      lineHeight: '2.2em',
      textTransform: 'uppercase',
    },
    lv2: {
      fontSize: '0.9rem',
      lineHeight: '2.6em',
    },
    lv3: {
      fontSize: '0.9rem',
      lineHeight: '2.6em',
    },
    activeColor: colors.primaryDark,
    selectedColor: colors.primary,
  },
};

//HEADER HEIGHTS
const header = {
  height: {
    lg: topBar.height.lg + desktopHeader.height.lg,
    md: topBar.height.md + desktopHeader.height.md,
    sm: topBar.height.md + mobileHeader.height.sm,
    xs: topBar.height.md + mobileHeader.height.xs,
    scroll: topBar.height.scroll + desktopHeader.height.scroll,
  },
  scrollBreak: 50,
};

//FOOTER
const footer = {
  background: '#D6EBB4',
  color: colors.font,
  logoWrapper: {
    background: colors.white,
  },
};

//CAMPAIGN BAR
const campaignBar = {
  primary: {
    background: colors.backgroundLight,
    color: colors.font,
  },
  secondary: {
    background: colors.primary,
    color: colors.white,
  },
};

//START PAGE
const startPage = {
  headers: {
    textTransform: 'uppercase',
    fontFamily: font.secondary,
    fontWeight: typography.header.fontWeight,
    fontSize: [
      '__',
      [remCalc(41), remCalc(62), remCalc(82), remCalc(95)],
      typography.header.fontSize[1],
      typography.header.fontSize[2],
      typography.header.fontSize[3],
      typography.header.fontSize[4],
    ],
  },
  iconBar: {
    background: colors.primaryDark,
    color: colors.white,
  },
  textBox: {
    background: colors.primary,
    color: colors.white,
  },
  row: {
    background: {
      primary: colors.primaryLight,
      secondary: colors.primaryDark,
    },
    color: {
      primary: colors.font,
      secondary: colors.white,
    },
    noImage: {
      backgroundLight: colors.backgroundLight,
      backgroundDark: colors.backgroundDark,
    },
  },
  readMore: {
    color: colors.font,
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight,
  },
  cta: {
    fontSize: button.fontSize,
    color: colors.white,
    background: colors.primary,
    border: 'none',
    fontFamily: button.fontFamily,
    fontWeight: button.fontWeight,
    textTransform: button.textTransform,
    letterSpacing: button.letterSpacing,
    hover: {
      color: colors.white,
      background: colors.primaryDark,
      border: 'none',
    },
    secondary: {
      color: colors.white,
      background: colors.primaryDark,
      border: 'none',
      hover: {
        color: colors.white,
        background: colors.primary,
        border: 'none',
      },
    },
    third: {
      color: colors.white,
      background: 'transparent',
      border: '4px solid' + colors.white,
      hover: {
        color: colors.white,
        background: colors.primary,
        border: '4px solid' + colors.primary,
      },
    },
  },
};

//CATEGORY PAGE
const categoryPage = {
  subcategories: {
    nameTag: 'h4',
    fontWeight: typography.header.fontWeight,
  },
  filter: {
    markerColor: colors.buy,
    hoverColor: colors.buy,
    desktop: {
      titles: {
        fontSize: button.fontSize,
        fontFamily: button.fontFamily,
        fontWeight: button.fontWeight,
        textTransform: button.textTransform,
        letterSpacing: typography.letterSpacing,
      },
    },
    drawer: {
      titles: {
        fontSize: button.fontSize,
        fontFamily: button.fontFamily,
        fontWeight: button.fontWeight,
        textTransform: button.textTransform,
        letterSpacing: typography.letterSpacing,
      },
    },
  },
};

//PRODUCT GRID
const productGrid = {
  pagination: false,
  col: {
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  },
  space: {
    sm: 3,
    md: 3,
    lg: 2,
    xl: 2,
  },
  product: {
    padding: {
      sm: '0',
      md: '0',
      lg: '0',
    },
    imageAspect: '1:1',
    background: '#FFF',
    border: 'none',
    name: {
      fontFamily: typography.header.fontFamily,
      fontWeight: typography.header.fontWeight,
    },
  },
};

const price = {
  fontFamily: typography.header.fontFamily,
  fontWeight: typography.header.fontWeight,
  normal: {
    color: colors.font,
    fontSize: typography.header.fontSize[4],
  },
  new: {
    color: colors.discount,
    fontSize: typography.header.fontSize[4],
  },
  old: {
    color: colors.greys[5],
    fontSize: typography.header.fontSize[5],
    fontWeight: weights.normal,
  },
};

//PRODUCT PAGE
const productPage = {
  name: {
    fontWeight: productGrid.product.name.fontWeight,
  },
  price: {
    normal: {
      fontSize: typography.header.fontSize[2],
    },
    new: {
      fontSize: typography.header.fontSize[2],
    },
    old: {
      fontSize: typography.header.fontSize[3],
    },
  },
  imageAspect: '1:1',
  variantSelector: 'button', //dropdown || button
  buyButton: {
    background: colors.buy,
    color: colors.white,
  },
  tabs: {
    barActiveColor: colors.primary,
    header: {
      fontWeight: typography.header.fontWeight,
    },
  },
  productList: {
    headerColor: colors.font,
    subHeaderColor: colors.font,
  },
};

export const theme = {
  ...cssMediaQueries,
  font,
  weights,
  typography,
  colors,
  general,
  button,
  topBar,
  desktopHeader,
  mobileHeader,
  header,
  footer,
  campaignBar,
  startPage,
  categoryPage,
  productGrid,
  price,
  productPage,
};
