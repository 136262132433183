import React from 'react';
import boot from '@jetshop/core/boot/client';
import Shop from './components/Shop';
import config from './shop.config';
import { initVoyado } from './components/useVoyadoTracker';

boot(<Shop />, {
  ...config,
  trackers: [
    ...config.trackers,
    {
      name: 'voyado',
      initBrowser() {
        initVoyado('betteryou');
      },
      trackCart({ cart }) {
        if (cart?.id) {
          // We're sending null as cartRef if this is not present,
          // meaning the cart is actually never emptied.
          window.__cartId__ = cart.id;
        }
      },
    },
  ],
});
