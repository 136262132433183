import React from 'react';
import { styled } from 'linaria/react';
import ReactModal from 'react-modal';
import { useQuery } from 'react-apollo';
import CloseButton from '../../ui/CloseButton';
import { theme } from '../../Theming/Theming';
import ProductLink from '@jetshop/ui/ProductLink';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import NotifyWhenBack from '../../ProductPage/StockStatus/NotifyWhenBack';
import { BadgeWrapper, Name, SubName, ListPrice } from '../ProductCard';
import Badges from '@jetshop/ui/ProductList/Badges';
import { VariantSelector } from '../../ProductPage/VariantSelector/VariantSelector';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useProductValidationMessage } from '../../ProductPage/helpers';
import StockStatusIndicator from '../../ProductPage/StockStatus/StockStatusIndicator';
import quickBuyProductQuery from './QuickBuyProductQuery.gql';
import { ButtonUI } from '../../ui/ButtonUI';
import AddToCart from '../../Cart/AddToCart';
import t from '@jetshop/intl';

const Modal = styled(ReactModal)`
  outline: none;
  max-width: 400px;
  width: 95%;
  position: absolute;
  left: 50%;
  top: 2%;
  transform: translateX(-50%) translateY(0%);
  ${theme.above.sm} {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const modalStyles = {
  overlay: {
    background: 'rgba(0,0,0,0.5)',
  },
};

const ModalInner = styled('div')`
  position: relative;
  border: 1px solid rgb(204, 204, 204);
  background: #fff;
  overflow: auto;
  padding: 2rem;

  ${theme.below.md} {
    padding: 2rem;
  }

  ${theme.below.sm} {
    padding: 1.5rem 1rem;
  }
`;

const Close = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.3rem;
  z-index: 2;
  button {
    margin: 0;
    padding: 0;
  }
  ${theme.below.sm} {
    padding: 0.3rem;
  }
`;

const ProductWrapper = styled('div')`
  background-color: white;
`;

const ProductInnerWrapper = styled('div')`
  color: inherit;
  display: flex;
  width: 100%;
  flex-direction: column;
  h4 {
    padding: 0;
  }
`;

const Link = styled(ProductLink)`
  display: flex;
  width: 100%;
  text-decoration: none;
`;

const Image = styled(ProductImage)`
  // padding-top: 100%;
`;

const ImageWrapper = styled('div')``;

const Details = styled('div')`
  text-align: left;
  width: 100%;
  padding-top: 0.5rem;
`;

const StockStatusWrapper = styled('div')`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  .stock-label {
    //display: none;
  }
  .free-freight-stock {
    display: inline-block;
    margin: 0 0 0 0.5rem;
  }
`;

const VariantSelectorWrapper = styled('div')`
  margin-top: 1rem;
  > div {
    margin-bottom: 0;
    button {
    }
  }
`;

const ButtonWrapper = styled('div')`
  button {
    width: 100%;
  }
`;

const QuickBuyProduct = ({ cardProduct, close }) => {
  const result = useQuery(quickBuyProductQuery, {
    variables: { articleNumber: cardProduct.articleNumber },
  });

  const product = result.data ? result.data.product : cardProduct;
  const categoryPath = '';

  const hasImages = product.images && product.images.length > 0;
  const sizes = [1, 1, 1 / 2, 1 / 4];
  const aspect = '1:1';

  //GET VARIANTS HANDLING
  const variantHandler = useProductVariants(
    product.articleNumber ? product : undefined,
    {}
  );

  const {
    selectedVariant,
    validation: variantValidation,
    missingOptions,
  } = variantHandler;

  const stockStatus = useStockStatus(selectedVariant || product);
  const price = useDynamicPrice(product, selectedVariant);

  const { hasVariants } = product;
  const { validationMessage, enableValidation } = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus,
  });

  //SWITCH IMAGE FOR SELECTED
  const image = selectedVariant?.images[0] || product.images[0];
  return (
    <ProductWrapper data-testid="product">
      <ProductInnerWrapper>
        <ImageWrapper>
          {hasImages && (
            <Link product={product} categoryPath={categoryPath}>
              <Image
                aspect={aspect}
                sizes={sizes}
                alt={image.alt}
                src={image.url}
                crop={true}
                cover
              >
                <BadgeWrapper>
                  <Badges badges={product.badges} />
                </BadgeWrapper>
              </Image>
            </Link>
          )}
        </ImageWrapper>
        <Details className="details">
          <Link product={product} categoryPath={categoryPath}>
            <Name>{product.name}</Name>
          </Link>
          <SubName>{product.subName}</SubName>
          <>
            <ListPrice {...price} />
            {stockStatus && (
              <StockStatusWrapper>
                <StockStatusIndicator
                  status={stockStatus.status}
                  text={stockStatus.text}
                />
              </StockStatusWrapper>
            )}
            {product.hasVariants ? (
              <VariantSelectorWrapper>
                <VariantSelector
                  type="button"
                  product={product}
                  variantHandler={variantHandler}
                  showValidation={!!validationMessage}
                />
              </VariantSelectorWrapper>
            ) : null}
            <>
              {stockStatus.status === 'notifyWhenBack' ? (
                <NotifyWhenBack
                  text={stockStatus.text}
                  articleNumber={(selectedVariant || product).articleNumber}
                />
              ) : (
                <ButtonWrapper>
                  {product.isPackage ||
                  product.customerComments?.length > 0 ||
                  product.specifications ? (
                    <ButtonUI>
                      <Link product={product} categoryPath={categoryPath}>
                        {t('Go to product')}
                      </Link>
                    </ButtonUI>
                  ) : (
                    <AddToCart
                      product={product}
                      quantity={1}
                      selectedVariation={selectedVariant}
                    >
                      {(add) => (
                        <ButtonUI
                          css={{ marginTop: '1rem' }}
                          className="buy"
                          onClick={() => {
                            if (variantValidation === 'valid') {
                              add();
                              close();
                            } else enableValidation();
                          }}
                        >
                          {t('Add to cart')}
                        </ButtonUI>
                      )}
                    </AddToCart>
                  )}
                </ButtonWrapper>
              )}
            </>
          </>
        </Details>
      </ProductInnerWrapper>
    </ProductWrapper>
  );
};

export const ProductCardPopup = ({ close, cardProduct }) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={close}
      style={modalStyles}
      ariaHideApp={false}
    >
      <ModalInner>
        <Close>
          <CloseButton onClick={close} />
        </Close>
        <QuickBuyProduct
          cardProduct={cardProduct}
          close={close}
        ></QuickBuyProduct>
      </ModalInner>
    </Modal>
  );
};
