import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import CategoryLink from '@jetshop/ui/CategoryLink';
import CloseButton from '../../../ui/CloseButton';
import t from '@jetshop/intl';
import { theme } from '../../../Theming/Theming';
import { headerTransitions } from './DesktopHeader';
import { config } from '../../../../shop.config.custom';

const boxShadow = `
  box-shadow: 0px 3px 4px #00000012;
`;

const SubMenuWrapper = styled('div')`
  display: none;
  max-height: calc(100vh - ${theme.header.height.lg + 'px'});
  overflow: auto;
  overflow-x: hidden;
  background: white;
  z-index: 2;
  ${headerTransitions}
  //DIFFERENT TYPES
  &.vertical {
    top: ${theme.desktopHeader.height.lg + 'px'};
    ${theme.only.md} {
      top: ${theme.desktopHeader.height.md + 'px'};
    }
    position: absolute;
    width: 300px;
    left: 0;
    &[data-scrolling='true'] {
      top: ${theme.desktopHeader.height.scroll + 'px'};
    }
    .sub-menu-inner-wrapper {
      padding: 1rem ${theme.desktopHeader.nav.padding[0]};
      ${theme.above.xl} {
        padding: 1rem ${theme.desktopHeader.nav.padding[1]};
      }
    }
  }
  &.mega-menu {
    position: fixed;
    width: 100%;
    left: 0;
    top: ${theme.desktopHeader.height.lg + theme.topBar.height.lg + 'px'};
    ${boxShadow}
    ${theme.only.md} {
      top: ${theme.desktopHeader.height.md + +theme.topBar.height.md + 'px'};
    }
    &[data-scrolling='true'] {
      top: ${theme.desktopHeader.height.scroll + 'px'};
    }
    .sub-menu-inner-wrapper {
      padding: 2rem 3rem;
    }
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  z-index: 2;
  svg {
    width: 10px;
    height: 10px;
  }
`;

const SubMenuContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  .sub-menu-categories {
    width: 100%;
  }
  .sub-menu-products {
    //width: 70%;
  }
`;

const SubMenuCategories = styled('div')`
  opacity: 0;
  transition: all 0.2s;
  //LEVEL2
  > ul {
    margin: 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    > li {
      list-style: none;
      -webkit-column-break-inside: avoid; /* Chrome, Safari */
      page-break-inside: avoid; /* Theoretically FF 20+ */
      break-inside: avoid-column; /* IE 11 */
      display: table; /* Actually FF 20+ */
      //LEVEL3
      > ul {
        > li {
          > a {
          }
          //LEVEL4
          > ul {
            display: none;
            > li {
            }
          }
        }
      }
    }
  }
  li.open {
    > ul {
      display: block !important;
    }
  }
  li.show-all a {
    color: ${theme.colors.black};
    &:hover {
      color: ${theme.colors.primary};
    }
  }

  //DIFFERENT TYPES
  &.vertical {
    > ul {
      flex-direction: column;
      > li {
        width: 100%;
        line-height: 2.4em;
        > a {
          font-size: ${theme.desktopHeader.nav.subMenu.lv3.fontSize};
        }
        //LEVEL3
        > ul {
          display: none;
        }
      }
    }
  }
  &.mega-menu {
    > ul {
      > li {
        width: 25%;
        margin: 1rem 0;
        ${theme.only.lg} {
          width: 25%;
        }
        > a {
          font-size: ${theme.desktopHeader.nav.subMenu.lv2.fontSize};
          text-transform: uppercase;
          font-weight: ${theme.weights.bold};
        }
        //LEVEL3
        > ul {
          margin-top: 1rem;
          > li {
            line-height: 1.6em;
            > a {
              font-size: ${theme.desktopHeader.nav.subMenu.lv3.fontSize};
            }
            //LEVEL 4
            > ul {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const SubMenuBackground = styled('div')`
  //transition: all 0.2s;
  position: fixed;
  z-index: -1;
  width: 100%;
  left: 0;
  background: white;
  ${boxShadow}
  top: ${theme.desktopHeader.height.lg + theme.topBar.height.lg + 'px'};
  ${theme.only.md} {
    top: ${theme.desktopHeader.height.md + +theme.topBar.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    top: ${theme.desktopHeader.height.scroll + 'px'};
  }
`;

const ShowAll = styled(CategoryLink)`
  margin-top: 0.5rem;
  display: block;
  font-size: ${theme.desktopHeader.nav.subMenu.lv3.fontSize};
`;

const SubCategories = ({
  mainCategory,
  level,
  setActiveCategory,
  setSubMenu,
  toggleMenu,
  setToggleMenu,
}) => {
  let limiter = 100;
  if (level >= 3) limiter = 5;
  if (mainCategory.subcategories.length)
    return (
      <ul>
        {mainCategory.subcategories.map((category, index) => (
          <React.Fragment key={category.id}>
            {index <= limiter && (
              <li className={category.id === toggleMenu ? 'open' : ''}>
                <CategoryLink
                  onClick={() => {
                    setSubMenu(false);
                    setActiveCategory(category);
                  }}
                  category={category}
                >
                  {category.name}
                </CategoryLink>
                {category.subcategories &&
                  category.subcategories.length > 0 && (
                    <SubCategories
                      mainCategory={category}
                      level={level + 1}
                      setActiveCategory={setActiveCategory}
                      setSubMenu={setSubMenu}
                      toggleMenu={toggleMenu}
                      setToggleMenu={setToggleMenu}
                    />
                  )}
              </li>
            )}
          </React.Fragment>
        ))}
        {level === 3 && (
          <li className="show-all">
            <CategoryLink
              onClick={() => {
                setSubMenu(false);
                setActiveCategory(mainCategory);
              }}
              category={mainCategory}
            >
              {t('Show all')}
            </CategoryLink>
          </li>
        )}
      </ul>
    );
  else return null;
};

const SubMenu = ({ category, setActiveCategory, setSubMenu, scrolling }) => {
  const [toggleMenu, setToggleMenu] = useState(0);

  const [menuHeight, setMenuHeight] = useState(100);

  const subMenuRef = React.createRef();
  let subMenuType =
    config.settings.megamenuId === category.id ? 'mega-menu' : 'vertical'; //vertical || mega-menu

  //CHECK FOR LEVEL3 THEN SHOW MEGAMENU (DYNAMIC)
  /*
  const level3min = 3;
  let level3count = 0;

  if (category.subcategories.length > 2) subMenuType = 'mega-menu';
  else {
    for (let i = 0; i < category.subcategories.length; i++) {
      const subCategory = category.subcategories[i];
      if (
        subCategory &&
        subCategory.subcategories &&
        subCategory.subcategories.length > 0
      ) {
        level3count++;
        if (level3count === level3min) {
          subMenuType = 'mega-menu';
          break;
        }
      }
    }
  }*/
  useEffect(() => {
    //CLOSE OUTSIDE LISTENER
    const listener = (e) => {
      if (!subMenuRef.current) return;
      if (!subMenuRef.current.contains(e.target)) {
        setSubMenu(false);
      }
    };
    document.addEventListener('click', listener);

    //SETS BACKGROUND HEIGHT
    if (menuHeight !== (subMenuRef.current && subMenuRef.current.clientHeight))
      setMenuHeight(subMenuRef.current.clientHeight);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [menuHeight, setMenuHeight, setSubMenu, subMenuRef]);
  if (category && category.subcategories && category.subcategories.length) {
    return (
      <SubMenuWrapper
        data-scrolling={scrolling}
        className={cx('sub-menu-wrapper', subMenuType)}
      >
        <div ref={subMenuRef} className="sub-menu-inner-wrapper">
          <StyledCloseButton onClick={() => setSubMenu(false)} />
          <SubMenuContent>
            {subMenuType === 'vertical' && (
              <SubMenuBackground
                data-scrolling={scrolling}
                style={{
                  height: menuHeight,
                }}
              />
            )}
            <SubMenuCategories
              className={cx('sub-menu-categories', subMenuType)}
              style={{
                opacity: menuHeight && 1,
              }}
            >
              <SubCategories
                mainCategory={category}
                level={2}
                setActiveCategory={setActiveCategory}
                setSubMenu={setSubMenu}
                toggleMenu={toggleMenu}
                setToggleMenu={setToggleMenu}
              />
            </SubMenuCategories>
          </SubMenuContent>
          {subMenuType === 'vertical' && (
            <ShowAll
              category={category}
              className="show-all"
              onClick={() => {
                setActiveCategory(category);
                setSubMenu(false);
              }}
            >
              {t('Show all')}
            </ShowAll>
          )}
        </div>
      </SubMenuWrapper>
    );
  } else return null;
};

export default SubMenu;
