import React from 'react';
import { styled } from 'linaria/react';
import { ContentHtmlWrapper } from '../../ContentRender/ContentText';

const Wrapper = styled('div')`
  padding: 4rem 1rem;
  text-align: center;
  background: #ddd;
`;

const CustomerClub = ({ content }) => {
  return (
    <Wrapper>
      <ContentHtmlWrapper className={`content-html-text`}>
        {content}
      </ContentHtmlWrapper>
    </Wrapper>
  );
};

export default CustomerClub;
