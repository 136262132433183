export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    blog: {
      path: '/artiklar',
      id: 175,
      mainLevel: 1,
      menu: true,
      profileView: false,
    },
    blog2: {
      path: '/om-oss-1',
      id: 235,
      mainLevel: 1,
      menu: false,
      profileView: true,
    },
    myPages: {
      startPageId: 94,
      termsId: 95,
      campaignId: 96,
    },
    stores: {
      path: '/stores',
    },
    brand: {
      id: 0,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  addwish: {
    productPage: ['k5f50fcd61a06e65cd2f3a337', 'k5f50fcdb1a06e65cd2f3a523'],
    notFound: ['k5c0e9e926ff3911f17ce267a', 'k5c0e9e956ff3911f17ce26f9'],
    cart: 'k5f51f5eee1e3c518355a2d3d',
  },
  settings: {
    storeName: 'Betteryou',
    siteContentId: 76,
    megamenuId: 156,
  },
};
