import React, { useContext, useState } from 'react';
import { styled } from 'linaria/react';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import CloseButton from '../../ui/CloseButton';
import { theme } from '../../Theming/Theming';
import { ContentLink } from '../../ContentRender/ContentLink';
import { contentTextElements } from '../../ContentRender/ContentText';

const animationLength = 800;

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
  padding: 7px 2rem;
  text-align: center;
  background-color: ${theme.colors.secondary};
  max-width: 100%;
  opacity: 0;
  animation-name: enter;
  animation-duration: ${animationLength}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 50;
  &,
  > a {
    width: 100%;
  }
  &.unmounting {
    animation-name: exit;
  }
  &.primary {
    background: ${theme.campaignBar.primary.background};
    ${contentTextElements}, svg, a {
      color: ${theme.campaignBar.primary.color};
    }
  }
  &.secondary {
    background: ${theme.campaignBar.secondary.background};
    ${contentTextElements}, svg, a {
      color: ${theme.campaignBar.secondary.color};
    }
  }
  button {
    position: absolute;
    right: 10px;
    top: 50%;
    padding: 5px;
    margin: -5px;
    margin-top: -12px;
    svg {
    }
  }
  ${contentTextElements}, svg {
    display: inline-block;
    color: ${theme.colors.white};
    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes exit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const CampaignBar = () => {
  const [visible, setVisible] = useState(true);
  const [unmounting, setUnmounting] = useState(false);

  const {
    campaignBar: campaignBarHtml,
    showCampaignBar,
    campaignBarLink,
    campaignBarBackground,
  } = useContext(SiteContentContext);

  function close() {
    setUnmounting(true);
    setTimeout(() => {
      setVisible(false);
    }, animationLength);
  }

  return showCampaignBar && visible ? (
    <Wrapper
      className={`${unmounting ? 'unmounting' : ''} ${campaignBarBackground}`}
    >
      {campaignBarLink ? (
        <ContentLink to={campaignBarLink}>{campaignBarHtml}</ContentLink>
      ) : (
        <>{campaignBarHtml}</>
      )}
      <CloseButton onClick={close} />
    </Wrapper>
  ) : null;
};

export default CampaignBar;
