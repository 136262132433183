import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import NewsletterField from './NewsletterField';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth, { Inner } from '../../MaxWidth';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .image-wrapper {
    width: 100%;
    [data-flight-image-container] {
      ${theme.below.lg} {
        padding-bottom: 50% !important;
      }
      ${theme.below.md} {
        padding-bottom: 80% !important;
      }
      ${theme.below.sm} {
        padding-bottom: 110% !important;
      }
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem ${theme.general.pagePadding.sm};
    ${theme.above.md} {
      padding: 4rem ${theme.general.pagePadding.md};
    }
    .text {
      > h2,
      > h3,
      > h4,
      > h5,
      > p {
        margin: 0 0 0.5em;
      }
    }
    > * {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
    .newsletter-wrapper {
      input {
        background: ${theme.colors.white};
        border: none;
        margin-right: 5px;
      }
      button {
        background: ${theme.colors.white};
        color: ${theme.colors.font};
        font-weight: ${theme.weights.bold};
        text-transform: uppercase;
        opacity: 1 !important;
        border: none;
      }
    }
  }

  &.standard {
    background: #f1f1f1;
    .text-wrapper {
      max-width: 800px;
      margin: 0 auto;
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: ${theme.font.secondary};
        font-weight: ${theme.weights.bold};
      }
      .form-wrapper {
        margin-top: 1rem;
        > p {
          text-align: center;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  const { showNewsletter, newsletter, newsletterImage } =
    useContext(SiteContentContext);

  if (!showNewsletter) return null;

  return (
    <NewsletterBoxWrapper className={'standard'}>
      {newsletterImage && (
        <div className="image-wrapper">
          <Image
            aspect={'3:1'}
            sizes={[1, 1, 1, 1]}
            src={newsletterImage}
            alt={newsletterImage}
            crop={true}
            cover
          />
        </div>
      )}
      <MaxWidth>
        <Inner>
          <div className="text-wrapper">
            <div className="text">{newsletter}</div>
            <NewsletterField />
          </div>
        </Inner>
      </MaxWidth>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
