import React from 'react';
import { theme } from '../../Theming/Theming';
import VariantButton from './VariantButtons';
import VariantDropdown from './VariantDropdown';

export const VariantSelector = ({
  product,
  variantHandler,
  type = theme.productPage.variantSelector,
  showValidation
}) => {
  return (
    <div>
      {product &&
        product.variants &&
        product.variants.options.map(option => {
          let showValidationMessage = false;
          if (
            showValidation &&
            variantHandler.getMissingOptions()?.includes(option)
          ) {
            showValidationMessage = true;
          }
          if (type === 'button') {
            return (
              <VariantButton
                option={option}
                variantHandler={variantHandler}
                key={option.name}
                showValidationMessage={showValidationMessage}
              />
            );
          }
          return (
            <VariantDropdown
              key={option.name}
              option={option}
              product={product}
              variantHandler={variantHandler}
              showValidationMessage={showValidationMessage}
            />
          );
        })}
    </div>
  );
};
