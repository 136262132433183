import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import StockOrb, { stockColors } from './StockOrb';
import { styled } from 'linaria/react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from '../../ui/Popup';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const StockStatusWrapper = styled('p')`
  margin: 0 0 0 0;
  display: flex;
  align-items: baseline;
  color: ${({ status }) => stockColors[status]};
  cursor: pointer;
  svg {
    margin-left: 6px;
    font-size: 1rem;
  }
`;

const StockStatusIndicator = ({ status, text }) => {
  const [popup, setPopup] = useState(false);
  const { stockStatusInfo } = useContext(SiteContentContext);

  if (text?.search('Beställningsvara') !== -1 && status === 'inStock')
    status = 'notifyWhenBack';
  return (
    <>
      <StockStatusWrapper status={status} onClick={() => setPopup(true)}>
        <StockOrb status={status} />
        <span>{stockStatusMask(text)}</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </StockStatusWrapper>
      <Popup isOpen={popup} closePopup={() => setPopup(false)}>
        {stockStatusInfo}
      </Popup>
    </>
  );
};

export const stockStatusMask = (status) => {
  /*const value = parseInt(status);
  if (value === 0) return t('Out of stock');
  if (value < 3) return t('A few left');
  if (value > 2) return t('In stock');*/
  return status;
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string,
};

export default StockStatusIndicator;
